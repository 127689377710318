const initialState = null;

const DriverReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_DRIVER_REQUEST':
      return {
        ...state,
        loading: true,
        error: null,
      };

    case 'FETCH_DRIVER_SUCCESS':
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case 'FETCH_DRIVER_RESULTS_SUCCESS':
      return {
        ...state,
        loading: false,
        results: action.payload,
      };

    case 'FETCH_DRIVER_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case 'FETCH_DRIVER_DETAIL_RESULTS_SUCCESS':
      return {
        ...state,
        loading: false,
        results: action.payload,
      };

    default:
      return state;
  }
};

export default DriverReducer;
